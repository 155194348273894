//import React, { Component, useContext } from "react";
import React, { Component } from "react";
import { Link } from "@reach/router";
//import { auth } from "../firebase";
//import { UserContext } from "./UserProvider";


export class Navigation extends Component {
  render() {
    //let user = useContext(UserContext);

    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className="navbar-brand page-scroll" href="#page-top">
              Ultimate Copy
            </a>{" "}
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a href="/" className="page-scroll">
                  Home
                </a>
              </li>
              <li> 
                <a href="/#features" className="page-scroll">
                  Features
                </a>
              </li>
              <li>
                <a href="/#screenshot" className="page-scroll">
                  Screenshots
                </a>
              </li>
              <li>
                <a href="/#extension" className="page-scroll">
                  Extensions
                </a>
              </li>
              <li>
                <a href="/#contact" className="page-scroll">
                  Contact
                </a>
              </li>
              <li>
                 <Link to="authentication"  className="text-blue-500 hover:text-blue-600">
                 Login/Logout
                </Link>
              </li>
              <li>
                <a href="https://ultimate.codes" className="page-scroll">
                  Company
                </a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navigation;
